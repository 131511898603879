export function parseSearch(search) {
    const searchParams = new URLSearchParams(search);
    const page = parseInt(searchParams.get('page')) || 1;
    const query = searchParams.get('q') || "";
    const categoryData = searchParams.get("category");
    const typesData = searchParams.get("postType");
    const tagsData = searchParams.get("postTag");
    const industryData = searchParams.get("industry");
    const servicesData = searchParams.get("services");
    const technologyData = searchParams.get("technology");
    let categories = [];
    let postTypes = [];
    let tags = [];
    let industry = [];
    let services = [];
    let technology = [];
    if (categoryData) {
        categories = categoryData.split(',').map(tag => {
            return parseInt(tag);
        });
    }
    if (typesData) {
        postTypes = typesData.split(',').map(tag => {
            return parseInt(tag);
        });
    }
    if (tagsData) {
        tags = tagsData.split(',').map(tag => {
            return parseInt(tag);
        });
    }
    if (industryData) {
        industry = industryData.split(',').map(tag => {
            return parseInt(tag);
        });
    }
    if (servicesData) {
        services = servicesData.split(',').map(tag => {
            return parseInt(tag);
        });
    }
    if (technologyData) {
        technology = technologyData.split(',').map(tag => {
            return parseInt(tag);
        });
    }
    const qParams = {
        page,
        query,
        categories,
        postTypes,
        tags,
        industry,
        services,
        technology,
    };
    return qParams;
}
export function updateHistory(state) {
    const url = new URL(document.location.toString());
    url.search = writeQueryFromKeys(state);
    history.pushState({}, "", url);
}
export function writeQueryFromKeys(state) {
    const searchParams = new URLSearchParams("");
    for (const [key, value] of Object.entries(state)) {
        switch (key) {
            case "page":
                if (state.page > 1) {
                    searchParams.append("page", state.page.toString());
                }
                break;
            case "query":
                if (value.length) {
                    searchParams.append("q", value);
                }
                break;
            default:
                if (!Array.isArray(value)) {
                    console.warn(`${key} should not be part of HistoryState`);
                    break;
                }
                let terms = value.filter((term) => {
                    return term.active;
                }).map((term) => {
                    return term.termId;
                });
                if (terms.length) {
                    searchParams.append(key, terms.join(","));
                }
                break;
        }
    }
    return searchParams.toString();
}
